import React from "react"
import { graphql } from "gatsby"

import Layout, { Section } from "../components/Layout"
import PageImage from "../components/PageUnsplashImage"
import { SingleEvent } from "../components/Events"

const Page = ({ data, location }) => {
  const event = data.event

  return (
    <Layout title={event.frontmatter.title}>
      <div>
        <PageImage src={event.frontmatter.image} credit={event.frontmatter.imageCredit}>
          <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
            {event.frontmatter.title}
          </h1>
        </PageImage>

        <Section className="container is-max-desktop">
          <div className="content markdown">
            <SingleEvent event={event} />
          </div>
          <br />
          <br />
        </Section>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query EventPage($id: String!) {
    event: markdownRemark(id: { eq: $id }) {
      html
      id
      fields {
        slug
      }
      frontmatter {
        image
        imageCredit {
          author
          href
        }
        title
        description
        notes
        date
        dateOverride
        startTime
        endTime
        form
        youtubeURL
        confirmed
        link
        linkText
        promoImage
      }
    }
  }
`
